<template>
  <div class="viewCoupon">
    <PageTitle :pageTitle="$route.meta.title"></PageTitle>
    <div class="mainContent">
      <div class="couponInfo">
        <img src="./img/coupon.png" alt="" />
        <div class="info">
          <div class="name">{{ couponInfo.coupon_name }}</div>
          <div class="type">
            <template v-if="couponInfo.coupon_type == 0">
              满{{ couponInfo.threshold_amount }}减{{ couponInfo.deduction_amount }}
            </template>
            <template v-if="couponInfo.coupon_type == 1">
              无门槛减{{ couponInfo.deduction_amount }}
            </template>
          </div>
          <div class="used">
            使用时间：
            <template v-if="couponInfo.validity_type == 0">
              {{ couponInfo.validity_start_time }}-{{ couponInfo.validity_end_time }}
            </template>
            <template v-if="couponInfo.validity_type == 1">
              自领取起{{ couponInfo.validity_day }}天内有效
            </template>
          </div>
        </div>
      </div>
      <div class="couponData">
        <div class="title">
          数据总览
          <vh-tooltip effect="dark" placement="right" v-tooltipMove>
            <div slot="content">数据更新频率：5分钟</div>
            <i class="iconfont-v3 saasicon_help_m"></i>
          </vh-tooltip>
        </div>
        <div class="data">
          <div class="item">
            <div class="text">
              用券总成交额
              <vh-tooltip effect="dark" placement="right" v-tooltipMove>
                <div slot="content">使用该优惠券订单付款成功的总金额</div>
                <i class="iconfont-v3 saasicon_help_m"></i>
              </vh-tooltip>
            </div>
            <div class="value">￥{{ couponData.transaction_amount || 0 }}</div>
          </div>
          <div class="item">
            <div class="text">
              优惠总金额
              <vh-tooltip effect="dark" placement="right" v-tooltipMove>
                <div slot="content">使用该优惠券付款成功后，优惠的总金额</div>
                <i class="iconfont-v3 saasicon_help_m"></i>
              </vh-tooltip>
            </div>
            <div class="value">￥{{ couponData.discount_amount || 0 }}</div>
          </div>
          <div class="item">
            <div class="text">
              领券总用户数
              <vh-tooltip effect="dark" placement="right" v-tooltipMove>
                <div slot="content">
                  {{
                    isInWebinar
                      ? '优惠券到账的总用户数，人数排重'
                      : '优惠券到账的总用户数，同一活动人数排重，不同活动人数不排重'
                  }}
                </div>
                <i class="iconfont-v3 saasicon_help_m"></i>
              </vh-tooltip>
            </div>
            <div class="value">{{ couponData.get_coupon_user_count || 0 }}</div>
          </div>
          <div class="item">
            <div class="text">
              用券总用户数
              <vh-tooltip effect="dark" placement="right" v-tooltipMove>
                <div slot="content">
                  {{
                    isInWebinar
                      ? '使用该优惠券的付款成功的总用户数，人数排重'
                      : '使用该优惠券付款成功的总用户数，同一活动人数排重，不同活动人数不排重'
                  }}
                </div>
                <i class="iconfont-v3 saasicon_help_m"></i>
              </vh-tooltip>
            </div>
            <div class="value">{{ couponData.use_coupon_user_count || 0 }}</div>
          </div>
        </div>
      </div>
      <div class="listContent">
        <vh-tabs v-model="activeName" @tab-click="handleClickTab">
          <vh-tab-pane label="用券商品" name="goods">
            <div v-if="!total_goods" class="oh">
              <null-page :nullType="'nullData'" :text="'暂无数据'"></null-page>
            </div>
            <vh-table
              v-else
              :data="data_goods"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="{ background: '#f7f7f7', color: '#666', height: '56px' }"
            >
              <vh-table-column label="图片">
                <template slot-scope="scope">
                  <div class="gift-cover">
                    <img :src="scope.row.goods_cover_image" alt="" />
                  </div>
                </template>
              </vh-table-column>
              <vh-table-column
                label="商品名称"
                prop="goods_name"
                show-overflow-tooltip
              ></vh-table-column>
              <vh-table-column label="购买数量" prop="buy_nums"></vh-table-column>
              <vh-table-column
                label="所属活动"
                prop="webinar_name"
                show-overflow-tooltip
              ></vh-table-column>
              <vh-table-column label="付款成功人数" prop="payment_success_nums"></vh-table-column>
            </vh-table>
            <SPagination
              :total="total_goods"
              :page-size="searchParams_goods.page_size"
              :currentPage="searchParams_goods.page"
              @current-change="currentChange_goods"
              @size-change="sizeChange_goods"
            ></SPagination>
          </vh-tab-pane>
          <vh-tab-pane label="领/用券用户" name="user">
            <div class="filterGroup" v-if="!(!total_user && !isSearch_user)">
              <div>
                <vh-select
                  round
                  size="medium"
                  filterable
                  v-model="searchParams_user.time_type"
                  style="width: 100px"
                  @change="getListUser('clearPage')"
                  class="mr10 mb15"
                >
                  <vh-option label="领券时间" :value="0" />
                  <vh-option label="用券时间" :value="1" />
                </vh-select>
                <vh-date-picker
                  v-model="pickDate"
                  size="medium"
                  round
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd HH:mm:ss"
                  type="datetimerange"
                  prefix-icon="iconfont-v3 saasicon_date"
                  @change="changeDate"
                  :unlink-panels="true"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  style="width: 360px"
                  class="mr10 mb15"
                />
              </div>
              <div>
                <vh-select
                  round
                  size="medium"
                  filterable
                  v-model="searchParams_user.use_status"
                  style="width: 100px"
                  @change="getListUser('clearPage')"
                  placeholder="全部状态"
                  class="mr10 mb15"
                >
                  <vh-option label="全部状态" value="" />
                  <vh-option label="已领取" :value="0" />
                  <vh-option label="已使用" :value="2" />
                  <vh-option label="已作废" :value="1" />
                </vh-select>

                <vh-input
                  size="medium"
                  round
                  style="width: 320px"
                  :placeholder="
                    searchParams_user.search_type == 0
                      ? '请输入用户姓名/手机号'
                      : '请输入活动名称/活动ID'
                  "
                  v-model="searchParams_user.keywords"
                  class="input-with-select mr10 mb15"
                  @keyup.enter.native="getListUser('clearPage')"
                  clearable
                  @clear="getListUser('clearPage')"
                >
                  <i
                    class="vh-icon-search vh-input__icon"
                    slot="prefix"
                    @click="getListUser('clearPage')"
                  ></i>
                  <vh-select
                    round
                    size="medium"
                    slot="prepend"
                    v-model="searchParams_user.search_type"
                    style="width: 100px"
                    @change="getListUser('clearPage')"
                  >
                    <vh-option label="用户信息" :value="0" />
                    <vh-option label="活动信息" :value="1" />
                  </vh-select>
                </vh-input>
                <vh-button type="default" round plain @click="exportData" v-preventReClick>
                  导出数据
                </vh-button>
              </div>
            </div>
            <div v-if="!total_user && !isSearch_user" class="oh">
              <null-page :nullType="'nullData'" :text="'暂无数据'"></null-page>
            </div>
            <vh-table
              v-if="total_user || isSearch_user"
              :data="data_user"
              tooltip-effect="dark"
              style="width: 100%"
              ref="refTableUser"
              :header-cell-style="{ background: '#f7f7f7', color: '#666', height: '56px' }"
            >
              <vh-table-column
                label="用户姓名"
                prop="user_name"
                show-overflow-tooltip
              ></vh-table-column>
              <vh-table-column label="手机号" prop="phone" show-overflow-tooltip></vh-table-column>
              <vh-table-column label="领取方式">
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.receive_type | typeFilter }}
                  </span>
                </template>
              </vh-table-column>
              <vh-table-column
                label="领券时间"
                prop="receive_time"
                show-overflow-tooltip
              ></vh-table-column>
              <vh-table-column label="状态">
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.use_status | statusFilter }}
                  </span>
                </template>
              </vh-table-column>
              <vh-table-column label="用券时间" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.use_time || '-' }}
                  </span>
                </template>
              </vh-table-column>
              <vh-table-column
                label="所属活动"
                prop="webinar_name"
                show-overflow-tooltip
              ></vh-table-column>
              <vh-table-column label="操作" width="80" fixed="right" v-if="isInWebinar">
                <template slot-scope="scope">
                  <vh-button
                    v-preventReClick
                    v-if="scope.row.use_status == 0"
                    class="btns"
                    type="text"
                    @click="toCancel(scope.row)"
                  >
                    作废
                  </vh-button>
                </template>
              </vh-table-column>
              <div slot="empty">
                <null-page
                  text="暂未搜索到您想要的内容"
                  nullType="search"
                  :height="100"
                  v-if="!total_user"
                ></null-page>
              </div>
            </vh-table>
            <SPagination
              :total="total_user"
              :page-size="searchParams_user.page_size"
              :currentPage="searchParams_user.page"
              @current-change="currentChange_user"
              @size-change="sizeChange_user"
            ></SPagination>
          </vh-tab-pane>
        </vh-tabs>
      </div>
    </div>
  </div>
</template>

<script>
  import PageTitle from '@/components/PageTitle';
  import NullPage from '../../PlatformModule/Error/nullPage.vue';

  export default {
    name: 'CouponView',
    components: {
      PageTitle
    },
    data() {
      return {
        couponInfo: {}, //基本信息
        couponData: {}, //数据总览
        activeName: 'goods',
        searchParams_goods: {
          page_size: 10,
          page: 1
        },
        data_goods: [], // 列表数据
        total_goods: 0, // 列表总数
        isSearch_user: false,
        searchParams_user: {
          page_size: 10,
          page: 1,
          time_type: 0,
          begin_time: '',
          end_time: '',
          use_status: '',
          search_type: 0,
          keywords: ''
        },
        data_user: [], // 列表数据
        total_user: 0, // 列表总数
        pickDate: '',
        isInWebinar: false,
        webinar_id: '',
        couponId: ''
      };
    },
    components: {
      NullPage,
      PageTitle
    },
    filters: {
      typeFilter(val) {
        const map = {
          0: '奖品兑换'
        };
        return map[val];
      },
      statusFilter(val) {
        const map = {
          0: '已领取',
          1: '已作废',
          2: '已使用'
        };
        return map[val];
      }
    },
    computed: {},
    created() {
      if (this.$route.path.indexOf('live/couponData') == -1) {
        this.isInWebinar = false;
        this.couponId = this.$route.params.couponId;
        this.webinar_id = '';
      } else {
        this.isInWebinar = true;
        this.couponId = this.$route.query.couponId;
        this.webinar_id = this.$route.params.str;
      }

      this.getCouponInfo();
      this.getCouponData();
      this.getListGoods('clearPage');
    },
    methods: {
      exportData() {
        const params = this.getRequestParams();
        this.$fetch('exportCouponUsersList', params, {
          'Content-Type': 'application/json'
        }).then(res => {
          if (res.code == 200) {
            this.$vhMessage.success(
              `导出成功， ${
                this.$store.state.permissionValue.custom_download_toast || '请去下载中心下载'
              }`
            );
          } else {
            this.$vhMessage.error('导出失败');
          }
        });
      },
      changeDate(val) {
        console.log(val);
        if (val) {
          this.searchParams_user.begin_time = val[0];
          this.searchParams_user.end_time = val[1];
        } else {
          this.searchParams_user.begin_time = '';
          this.searchParams_user.end_time = '';
        }
        this.getListUser('clearPage');
      },
      handleClickTab(tab, event) {
        console.log(tab, event);
        if (this.activeName == 'goods') {
          this.getListGoods('clearPage');
        } else {
          this.getListUser('clearPage');
        }
      },
      async getCouponInfo() {
        let res = await this.$fetch(
          'getCouponInfo',
          {
            coupon_id: this.couponId
          },
          {
            'Content-Type': 'application/json'
          }
        );
        if (res.code == 200 && res.data) {
          this.couponInfo = res.data;
        }
      },
      async getCouponData() {
        let res = await this.$fetch(
          'getCouponData',
          {
            webinar_id: this.webinar_id,
            coupon_id: this.couponId
          },
          {
            'Content-Type': 'application/json'
          }
        );
        if (res.code == 200 && res.data) {
          this.couponData = res.data;
        }
      },
      setIsSearch() {
        for (let key in this.searchParams_user) {
          if (
            Object.prototype.hasOwnProperty.call(this.searchParams_user, key) &&
            !['page_size', 'page', 'webinar_id'].includes(key)
          ) {
            if (this.searchParams_user[key]) {
              this.isSearch_user = true;
              return;
            }
            if (key == 'use_status' && this.searchParams_user[key] === 0) {
              this.isSearch_user = true;
              return;
            }
          }
          this.isSearch_user = false;
        }
      },
      getRequestParams() {
        return {
          ...this.searchParams_user,
          webinar_id: this.webinar_id,
          coupon_id: this.couponId,
          pos: (this.searchParams_user.page - 1) * this.searchParams_user.page_size,
          limit: this.searchParams_user.page_size
        };
      },
      getListGoods(type) {
        if (type == 'clearPage') {
          this.searchParams_goods.page = 1;
        }
        this.$fetch(
          'getCouponGoodsList',
          {
            pos: (this.searchParams_goods.page - 1) * this.searchParams_goods.page_size,
            limit: this.searchParams_goods.page_size,
            webinar_id: this.webinar_id,
            coupon_id: this.couponId
          },
          {
            'Content-Type': 'application/json'
          }
        ).then(res => {
          if (res.code == 200 && res.data) {
            this.total_goods = res.data.total;
            this.data_goods = res.data.list;
          }
        });
      },
      getListUser(type) {
        if (type == 'clearPage') {
          this.searchParams_user.page = 1;
        }
        const params = this.getRequestParams();
        this.setIsSearch();
        this.$fetch('getCouponUsersList', params, {
          'Content-Type': 'application/json'
        }).then(res => {
          if (res.code == 200 && res.data) {
            this.total_user = res.data.total;
            this.data_user = res.data.list;
          }
        });
      },
      currentChange_goods(val) {
        this.searchParams_goods.page = val;
        this.getListGoods();
      },
      sizeChange_goods(val) {
        this.searchParams_goods.page = 1;
        this.searchParams_goods.page_size = val;
        this.getListGoods();
      },
      currentChange_user(val) {
        this.searchParams_user.page = val;
        this.getListUser();
      },
      sizeChange_user(val) {
        this.searchParams_user.page = 1;
        this.searchParams_user.page_size = val;
        this.getListUser();
      },
      toCancel(row) {
        this.$vhConfirm('作废优惠券后，该用户无法使用此优惠券，请提前告知用户', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          roundButton: true,
          customClass: 'zdy-message-box',
          lockScroll: false,
          cancelButtonClass: 'zdy-confirm-cancel'
        }).then(() => {
          this.$fetch(
            'cancelWebinarCoupon',
            { webinar_id: this.webinar_id, id: row.user_coupon_id },
            {
              'Content-Type': 'application/json'
            }
          )
            .then(res => {
              if (res && res.code === 200) {
                this.$vhMessage({
                  message: `作废成功`,
                  showClose: true,
                  // duration: 0,
                  type: 'success',
                  customClass: 'zdy-info-box'
                });
                // 刷新页面
                // this.$refs.refTableUser && this.$refs.refTableUser.clearSelection();
                this.getListUser('clearPage');
                // this.couponIds = [];
              }
            })
            .catch(res => {
              this.$vhMessage({
                message: res.msg || `删除失败`,
                showClose: true,
                // duration: 0,
                type: 'error',
                customClass: 'zdy-info-box'
              });
            });
        });
      }
    }
  };
</script>

<style lang="less">
  .viewCoupon .mainContent {
    > div {
      background-color: #fff;
      margin-bottom: 24px;
    }
    > .couponInfo {
      padding: 16px;
      display: flex;
      > img {
        width: 80px;
        max-height: 80px;
      }
      > .info {
        margin-left: 20px;
        .name {
          color: #1a1a1a;
          font-size: 20px;
          font-weight: 400;
          margin-bottom: 8px;
          line-height: 28px;
        }
        .type,
        .used {
          color: #555;
          font-size: 14px;
          font-weight: 400;
          margin-top: 4px;
          line-height: normal;
        }
      }
    }
    > .couponData {
      .title {
        padding: 24px 0 0 24px;
        color: #1a1a1a;
        font-size: 16px;
        font-weight: 400;
        .iconfont-v3 {
          color: #999;
        }
      }
      .data {
        display: flex;
        .item {
          width: 25%;
          height: 120px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          color: #999;
          font-size: 14px;
          font-weight: 400;
          &:nth-child(n + 3) {
            .value {
              margin-left: 0;
            }
          }
          .value {
            color: #1a1a1a;
            font-family: D-DIN;
            font-size: 28px;
            font-weight: bold;
            margin-top: 4px;
            width: 50%;
            margin-left: -8px;
          }
          .text {
            width: 50%;
          }
        }
      }
    }
    > .listContent {
      .vh-tabs__item {
        height: 54px;
        line-height: 54px;
        font-size: 16px;
      }
      .vh-tab-pane {
        // min-height: 400px;
        .filterGroup {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin: 8px 0 12px;
          > div {
            > div {
              margin-right: 12px;
              margin-bottom: 12px;
            }
          }
          .input-with-select {
            .vh-input-group__prepend {
              border-radius: 100px 0 0 100px;
            }
            .vh-input__inner {
              border-radius: 0 100px 100px 0;
            }
            .vh-input--suffix {
              .vh-input__inner {
                padding-left: 11px;
              }
            }
          }
        }
        .vh-table {
          .gift-cover {
            display: inline-block;
            width: 70px;
            min-width: 70px;
            height: 70px;
            // margin: 3px 0;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #e6e6e6;
            img {
              width: 100%;
              height: 100%;
              object-fit: scale-down;
            }
          }
        }
      }
    }
  }
</style>
